import './bootstrap';
import '../css/app.css';
import '../scss/app.scss';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from 'ziggy-js'
import {Head} from "@inertiajs/vue3";
import Vuetify from "./vuetify";
import '/resources/js/Assets/fontawesome-6-pro-web/css/all.min.css';
import '/resources/js/Assets/fontawesome-6-pro-web/css/fontawesome.min.css';

const appName = import.meta.env.VITE_APP_NAME || 'I.B.W.A'

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),

    setup({el, App, props, plugin}) {
        const app = createApp({render: () => h(App, props)})
            .use(plugin)
            .use(ZiggyVue)
            .use(Vuetify)
        app.component('Head', Head)
        return app.mount(el)
    },
    progress: {
        color: '#4B5563',
    },
}).then()
