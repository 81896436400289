import '../scss/_variables.scss';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { aliases, fa } from 'vuetify/iconsets/fa';

const ibwa = {
    dark: false,
    colors: {
        primary: '#212121',
        accent: '#FF9F1C',
        border: '#CCCCCC',
        text: '#ff3299',
        background: '#ffffff',
        footer: "#212121",
        error: '#D32F2F',
        info: '#007BFF',
        success: '#4CAF50',
        active: '#61B865',
        inactive: '#A9A9A9',
        warning: '#FFC107'
    },
    variables: {
        'medium-emphasis-opacity': 1,
    },
    font: {
        family: 'Roboto, sans-serif'
    }
}

const vuetify= createVuetify({
    components,
    directives,
    defaults: {
        global: {
            fontFamily: 'Roboto, sans-serif',
        },
        VBtn: {
            color: 'primary',
            rounded: 'pill',
            style: {
                textTransform: 'none',
                fontFamily: 'Roboto, sans-serif',
                fontWeight: '700'
            },
        },
        VTextField: {
            'hideDetails': 'auto'
        },
        VCheckbox: {
            'hideDetails': 'auto'
        },
        VTextarea: {
            'hideDetails': 'auto'
        },
        VSelect: {
            'hideDetails': 'auto'
        },
    },
    icons: {
        defaultSet: 'fa',
        aliases,
        sets: {
            fa,
        },
    },
    theme: {
        defaultTheme: 'ibwa',
        themes: {
            ibwa,
        },
    },
})

export default vuetify;
